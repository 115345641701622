import React from 'react';
import { JsonView, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css'; // Import default styles for JSON view

const TranscriptionHistory = ({ data }) => {
  const calls = data?.vocallabs_agent_by_pk?.calls || [];
  console.log("calls details received are", calls);

  return (
    <div className="bg-gray-800 p-4 rounded shadow text-gray-200">
      <h2 className="text-lg font-semibold mb-4">Transcription History</h2>
      {calls.map((call, index) => (
        <div key={index} className="mb-6">
          <h3 className="font-semibold">Call {index + 1}</h3>
          {call.conversation ? (
            <div className="mt-2">
              {/* Display conversation in JSON view with dark theme */}
              <JsonView
                data={call.conversation}
                style={darkStyles}
                collapsed={false} // Collapse all items initially
                collapseStringsAfter={50} // Limit for string collapsing
              />
            </div>
          ) : (
            <p className="text-gray-400">No conversation available for this call.</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default TranscriptionHistory;
